<template>
  <div>
    <div class="pt-2">
      <div class="shadow">
        <b-card
          no-body>
          <b-table
            :items="items"
            :fields="fields"
            striped
            hover
            responsive>
          </b-table>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fields: [
        { key: 'templateName', label: 'Template Name' },
        { key: 'subject', label: 'Subject' },
        { key: 'message', label: 'Message' }
      ],
      items: [
        {
          templateName: 'test002',
          subject: 'test002',
          message: 'test002'
        }
      ]
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
