<template>
  <div class="pl-2">
    <div
      v-for="(file, index) in attachment"
      :key="index"
      class="pb-1">
      <b-row>
        <b-col
          cols="1">
          <img
            src="@/assets/images/icons/ion_document-outline.svg">
        </b-col>
        <b-col cols="11">
          {{ file.name }}
        </b-col>
      </b-row>
    </div>
    <div class="pt-1">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="outline-primary"
        class="">
        <div class="d-flex align-items-center">
          <img
            src="@/assets/images/icons/majesticons_file-plus-line.svg" />
          <span class="pl-1">Attach File</span>
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      attachment: [
        { name: 'เอกสารแนบที่ 1.doc' },
        { name: 'เอกสารแนบที่ 2.doc' },
        { name: 'เอกสารแนบที่ 3.doc' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
div ::v-deep .col-1 {
  max-width: 3%;
}
</style>
