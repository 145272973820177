<template>
  <div>
    <ConfirmModal
      ref="confirmModal"
      variant="danger"
      :title="confirmModal.title"
      :description="confirmModal.description"
    />
    <b-tabs
      justified
      pills>
      <!-- Render Tabs, supply a unique `key` to each tab -->
      <b-tab
        v-for="(ticket, index) in tickets"
        :key="'dyn-tab-' + index"
        :title-link-class="tabActive === index ? 'rounded-tab text-white bg-primary border-danger' : 'rounded-tab text-primary bg-white border-primary'"
        :title="'Case' + index"
        @click="changeTab(index)"
      >
        <template #title>
          <div
            class="d-flex align-items-center justify-content-between"
            style="height: 20px;">
            <span class="d-inline-block"></span>
            <span
              v-if="ticket && ticket.code"
              class="d-inline-block">{{ ticket.code }}
            </span>
            <span
              v-else
              class="d-inline-block">
              Ticket
            </span>
            <span
              class="d-inline-block"
              @click="closeTab(index)">
              <b style="font-size: 20px;"><b-icon icon="x"></b-icon></b>
            </span>
          </div>
        </template>
        <TicketTab
          :tab-index="index"
          :item="ticket"
        />
      </b-tab>
      <!-- New Tab Button (Using tabs-end slot) -->
      <template
        v-if="tickets.length < 5"
        #tabs-end>
        <b-nav-item
          role="presentation"
          @click.prevent="newTab">
          <span style="font-size: 26px;">+</span>
        </b-nav-item>
      </template>
      <!-- Render this if no tabs -->
      <template>
        <div class="text-center text-muted">
        </div>
      </template>
    </b-tabs>

    <template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TicketProvider from '@/resources/TicketProvider'
import ConfirmModal from '@/components/ConfirmModal.vue'
import returnDataTicket from '@/utils/data-ticket'
import TicketTab from './TicketTab.vue'

const TicketService = new TicketProvider()

export default {
  components: {
    TicketTab,
    ConfirmModal
  },
  data () {
    return {
      tabActive: 0,
      tabs: [1],
      tabCounter: 1,
      confirmModal: {
        title: 'Are you sure ?',
        description: 'You want to close tabs ?'
      }
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      tickets: 'ticket/tickets',
      profile: 'User/user'
    })
  },
  methods: {
    ...mapActions({
      addTicket: 'ticket/ADD_TICKET',
      deleteTicket: 'ticket/DELETE_TICKET'
    }),
    async closeTab (x) {
      try {
        const confirm = await this.$refs.confirmModal.show({ description: 'You want to close tab ?' })
        if (confirm) {
          this.deleteTicket(x)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async newTab () {
      try {
        const { data } = await TicketService.createInitial(this.profile.id)
        const payload = returnDataTicket(data)
        this.addTicket(payload)
      } catch (error) {
        console.error(error)
      }
    },
    changeTab (index) {
      this.tabActive = index
    }
  }
}
</script>

<style lang="scss" scoped>
div ::v-deep .nav-pills {
  margin-bottom: 0rem;
}

div ::v-deep .rounded-tab {
  border: 1px solid #44aad1 !important;
}

</style>
