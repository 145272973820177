export default function returnDataTicket (data) {
  return {
    id: data.id || null,
    code: data.code || null,
    ticketTypeId: data.ticketTypeId || null,
    contactChannelId: data.contactChannelId || null,
    callCategoryId: data.callCategoryId || null,
    priority: data.priority || null,
    customerName: data.customerName || null,
    contactName: data.contactName || null,
    contactPhoneNo: data.contactPhoneNo || null,
    customerProfileId: data.customerProfileId || null,
    categoryLv1: data.categoryLv1 || null,
    categoryLv2: data.categoryLv2 || null,
    categoryLv3: data.categoryLv3 || null,
    categoryLv4: data.categoryLv4 || null,
    categoryLv5: data.categoryLv5 || null,
    categoryLv6: data.categoryLv6 || null,
    plantId: data.plantId || null,
    qty: data.qty || null,
    time: data.time || null,
    subject: data.subject || null,
    ticketCause: data.ticketCause || null,
    ticketResolve: data.ticketResolve || null,
    ticketDetail: data.ticketDetail || null,
    progressBy: data.progressBy || null,
    dueDate: data.dueDate || null,
    resolveDate: data.resolveDate || null,
    remark1: data.remark1 || null,
    remark2: data.remark2 || null,
    remark3: data.remark3 || null,
    attachFiles: data.attachFiles || [],
    caseStatus: data.caseStatus || null,
    createdBy: data.createdBy || null,
    createdAt: data.createdAt || null,
    ani: data.ani || null
  }
}
