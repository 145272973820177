<template>
  <div>
    <div class="pt-2">
      <div class="shadow">
        <b-card
          no-body>
          <b-table
            :items="items"
            :fields="fields"
            striped
            hover
            responsive>
            <template #cell(dateTime)="data">
              {{ data.item.dateTime.toLocaleString('th-TH', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
              }) }}
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  data () {
    return {
      fields: [
        { key: 'dateTime', label: 'Date and Time' },
        { key: 'templateName', label: 'Template Name' },
        { key: 'message', label: 'message' }
      ],
      items: [
        {
          dateTime: new Date(),
          templateName: 'test001',
          message: 'test0012'
        }
      ]
    }
  },
  created () {
    const date = dayjs().format('YYYY-MM-DD')
    console.log(date)
  }
}
</script>

<style lang="scss" scoped>

</style>
